<template>
  <div class="page-box" ref='page'>
    <a-spin tip="Loading..." :spinning="formLoading">
      <a-form-model
        :loading="formLoading"
        ref="ruleForm"
        :model="form"
        :rules="isView?{}:rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="名称：" prop="name">
          <a-input
            v-if="!isView"
            v-model="form.name"
            style="width: 300px"
            allowClear
            placeholder="兑换券名称"
          />
          <div v-else>{{form.name}}</div>
        </a-form-model-item>
        <a-form-model-item label="有效期截止时间：" prop="validEndTime">
          <a-date-picker
            v-if="!isView"
            v-model="form.validEndTime"
            :disabled-date="disabledEndTime"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="截止时间"
          />
          <div v-else>{{form.validEndTime}}</div>
        </a-form-model-item>
        <a-form-model-item label="关联商品：" prop="productId">
          <div v-if="selectSku" class="good-name-box">
            <base-img
              :src="selectSku.skuPic||selectSku.mainPicPath"
              style="flex-shrink:0;margin-right:8px;"
              width="60"
              height="60"
            ></base-img>
            <div style="flex: 1; line-height: 1.2em;">
              <div class="product">
                商品：<a-button
                  class="name"
                  type="link"
                  style="padding: 0; line-height: inherit; height: auto; padding-bottom: 10px;"
                  @click="goGoodDetail(selectProduct.productId)"
                >{{selectProduct.name}}</a-button>
              </div>
              <div class="sku">
                规格：{{selectSku.skuName || '默认规格'}}
              </div>
            </div>
            <a-button v-if="!isView" type="link" @click="showSelectProductPanel">重选</a-button>
          </div>
          <a-button
            v-if="!selectSku && !isView"
            type="link"
            @click="showSelectProductPanel"
          >选择</a-button>
        </a-form-model-item>
        <a-form-model-item label="发放张数：" prop="voucherNum">
          <a-input
            v-if="!isView"
            addon-after="张"
            v-model="form.voucherNum"
            style="width: 160px"
            placeholder="请输入"
          />
          <div v-else-if="form.voucherNum">{{form.voucherNum}}张</div>
        </a-form-model-item>

        <a-form-model-item label="兑换须知：" prop="remark">
          <a-textarea
            v-if="!isView"
            placeholder="请输入"
            :rows="4"
            v-model="form.remark"
          />
          <div v-else>{{form.remark}}</div>
        </a-form-model-item>

        <a-row type="flex" align="middle" justify="center" v-if='!isView'>
          <a-button type="primary" @click="onSubmit" :loading="saveBtnLoading">保存</a-button>
          <a-button style="margin-left: 10px;" @click="closePage">取消</a-button>
        </a-row>
      </a-form-model>
    </a-spin>
    <a-modal
      title="选择商品"
      :visible="modalShow"
      width="800px"
      @cancel="modalCancel"
      @ok="modalConfirm"
      :getContainer="()=>$refs.page">
      <div class="search-box"
        @keydown.enter="doSearch"
      >
        <a-input placeholder="搜索商品名称" style="width: 200px;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="doSearch">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-spin class="" :spinning="modalLoading">
        <div class="product-list">
          <div v-for="p in productList" :key="p.productId" class="good-name-box">
            <base-img
              :src="p.mainPicPath"
              style="flex-shrink:0;margin-right:8px;"
              width="60"
              height="60"
            ></base-img>
            <div class="good-name">
              <a-button
                class="name"
                type="link"
                @click="goGoodDetail(p.productId)"
              >{{p.name}}</a-button>
            </div>
            <a-radio
              :checked="draftProduct && draftProduct.productId === p.productId"
              @change="draftProduct = p"
            ></a-radio>
          </div>
        </div>
        <a-pagination
          class="pagi"
          size="small"
          v-model="pagination.current"
          :total="pagination.total"
          show-less-items
          :page-size="pagination.pageSize"
          @change="handleTableChange"
        />
      </a-spin>
      <div v-if="draftProduct" class="sku-container">
        <div style="padding-bottom: 15px">规格：</div>
        <a-spin :spinning="skuLoading">
          <a-radio-group v-model="draftSku" button-style="solid">
            <a-radio-button v-for="s in skuList"
              :value="s"
              :key="s.productSkuId"
            >
              {{s.skuName || '默认规格'}}
            </a-radio-button>
          </a-radio-group>
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, watch, toRefs, computed, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  name: 'PageExchangeForm',
  props: {
    id: String,
    readonly: String
  },
  setup (props, { root }) {
    // modal
    const columns = [
      {
        title: '商品名称',
        dataIndex: 'name',
        width: 300,
        scopedSlots: { customRender: 'name' }
      },
    ]
    // 商品
    const columns1 = [
      {
        title: '商品名称',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
      },
      {
        title: '零售价',
        dataIndex: 'price',
        scopedSlots: { customRender: 'price' }
      },
      {
        title: '成本价',
        dataIndex: 'supplyPrice',
        scopedSlots: { customRender: 'supplyPrice' }
      },
      {
        title: '库存',
        dataIndex: 'stock',
        scopedSlots: { customRender: 'stock' }
      }
    ]
    const labelCol = { span: 3 }
    const wrapperCol = { span: 20 }
    const rules = {
      name: [
        { required: true, message: '请输入兑换券名称', trigger: 'change' },
        { max: 32, message: '请尝试简短一些的兑换券名称', trigger: 'change' }
      ],
      validEndTime: [
        { required: true, message: '请输入截止时间', trigger: 'change' },
      ],
      productId: [
        { required: true, message: '请选择兑换券关联的商品', trigger: 'change' }
      ],
      voucherNum: [
        { required: true, message: '请输入发放张数', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            if (value) {
              const reg = /^[1-9][0-9]*$/
              if (reg.test(value)) {
                return callback()
              } else {
                return callback(new Error('请输入一个整数'))
              }
            } else {
              callback()
            }
          }
        }
      ],
    }
    const isView = computed(() => Boolean(props.readonly))
    const state = reactive({
      formLoading: false,
      modalShow: false,
      modalLoading: false,
      saveBtnLoading: false,
      draftProduct: null,
      draftSku: null,
      selectProduct: null,
      selectSku: null,
      form: {
        name: '',
        validEndTime: '',
        productId: computed(() => state.selectProduct && state.selectProduct.productId),
        productSkuId: computed(() => state.selectSku && state.selectSku.productSkuId),
        productName: computed(() => state.selectProduct && state.selectProduct.name),
        productSkuPic: computed(() => state.selectSku && state.selectSku.skuPic),
        productSkuName: computed(() => state.selectSku && state.selectSku.skuName),
        voucherNum: '',
        remark: '',
      },
      productList: [],
      ruleForm: null,
      productName: '',
      pagination: {
        current: 1,
        pageSize: 12,
        total: 0,
      },
      skuList: [],
      skuLoading: false,
    })
    onMounted(() => {
      if (props.id) {
        const o = JSON.parse(decodeURIComponent(props.id))
        const f = state.form
        f.name = o.name
        f.voucherNum = o.voucherNum
        f.remark = o.remark
        f.validEndTime = o.validEndTime
        state.selectProduct = {
          name: o.productName,
          productId: o.productId
        }
        state.selectSku = {
          skuName: o.productSkuName,
          mainPicPath: o.mainPicPath,
          skuPic: o.productSkuPic
        }
      }
    })
    function onSubmit () {
      state.ruleForm.validate(async valid => {
        if (valid) {
          state.saveBtnLoading = true
          let { code, msg } = await activity.createVoucher({
            shopId: getSession(SHOPID),
            ...state.form,
          })
          state.saveBtnLoading = false
          if (code === '00000') {
            root.$message.success('创建成功！')
            root.$bus.$emit('PageExchange:refresh')
            closePage()
          } else {
            root.$message.error(msg || '创建失败！')
          }
        }
      })
    }
    function closePage () {
      if (
        root.$store.state.tabs.views
          .map(item => item.path)
          .indexOf('/activity/bargain') !== -1
      ) {
        root.$closeCurrentView()
      } else {
        root.$closeCurrentView('/activity/exchange')
      }
    }
    function showSelectProductPanel () {
      state.modalShow = true
    }
    onMounted(() => {
      fetchProducts()
    })
    async function fetchProducts () {
      state.modalLoading = true
      let {
        code, data, page, msg
      } = await activity.getVoucherSelectProductPage({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.productName
      })
      state.modalLoading = false
      if (code === '00000') {
        state.productList = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange (current, pageSize) {
      state.pagination.current = current
      state.pagination.pageSize = pageSize
      fetchProducts()
    }
    function modalCancel () {
      state.draftProduct = state.selectProduct
      state.draftSku = state.selectSku
      state.modalShow = false
    }
    function modalConfirm () {
      state.selectProduct = state.draftProduct
      state.selectSku = state.draftSku
      state.modalShow = false
    }
    function goGoodDetail (productId) {
      root.$router.push(
        `/goods/goods_detail/${productId}`
      )
    }
    function deletSelectProduct () {
      state.selectProduct = null
      state.selectSku = null
    }
    function doSearch () {
      state.pagination.current = 1
      fetchProducts()
    }
    function resetSearch () {
      state.productName = ''
      doSearch()
    }
    function disabledEndTime (endValue) {
      return endValue.isBefore(moment().add(1, 'hour'))
    }
    watch(() => state.draftProduct, async (v) => {
      state.skuLoading = true
      const { data } = await activity.getSkusByProductId(v.productId)
      state.skuLoading = false
      state.skuList = data || []
      if (data.length) {
        state.draftSku = data[0]
      }
    })
    return {
      disabledEndTime,
      labelCol,
      wrapperCol,
      rules,
      moment,
      columns,
      columns1,
      ...toRefs(state),
      onSubmit,
      handleTableChange,
      modalCancel,
      modalConfirm,
      goGoodDetail,
      deletSelectProduct,
      resetSearch,
      closePage,
      isView,
      showSelectProductPanel,
      doSearch,
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .good-name-box {
  display: flex;
  align-items: center;
  width: 300px;
  .good-name {
    min-height: 60px;
    width: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    padding-right: 10px;
    flex-wrap: wrap;

    .name {
      padding: 0;
      text-align: left;
      font-size: inherit;
      white-space: unset;
      height: auto;
    }
  }
}
.group-price-form {
  ::v-deep .ant-form-item {
    margin-bottom: 0;
  }
}
.batch-box {
  display: flex;
  align-items: center;
}
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: flex-end;
}
::v-deep .ant-form-item {
  margin-bottom: 12px;
}
.sku-box {
  display: flex;
  align-items: center;
  div {
    padding: 0 12px;
  }
}
.tips {
  font-size: 12px;
}
.product-list {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -5px;
}
.product-list > * {
  flex: 0 0 calc(33.3333% - 10px);
  margin: 0 5px;
  margin-bottom: 10px;
}
.good-name-box {
  border: 1px solid #eee;
  border-radius: 3px;
  overflow: hidden;
}
.pagi {
  text-align: right;
  padding-top: 15px;
}
.sku-container {
  position: relative;
  width: 100%;
  padding-top: 30px;
}
.sku-container::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  height: 0;
  border-top: 1px solid #eee;
}
.tip {
  font-size: 12px;
  color: #999;
}
</style>
